import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/chateaudemorey/src/components/Layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><strong parentName="h1">{`Airbnb`}</strong>{` proche de `}<strong parentName="h1">{`Nancy`}</strong>{` et `}<strong parentName="h1">{`Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "527px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/70a3b19265fa86c48c8ce00f41e3a561/44385/LesChambresTourelle1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.39999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAADJElEQVQozwEZA+b8AI9bE49nNyAZFAQFBVc9H55sLohaJqJrJqRtH6t4Jqx1I6x1I7B2Iq1wG6JiEadnGKxvH6ltHaJnG5pgGACTXRWSYiR+ViWdcDWgbS2cZSCmcSyscyi6fCK6gSq6hC21fSiweiexeiyZYBmjaR6ydCGrbh6pbSChZxwAlV0ThVcdSTEXa0khlWYxr389qXc1rHg2u4U3pG0ltoI3toI5rXkznGQhnGQkq24it3knrnIjrHAjpmofAJBYEIVUGBcPCgAAAFA5LrZ/PKVvK7F5Mb6BLZthHLB1I6ZoG55gF5ZXDbB5M7V4Lb2BLbV4KLByJp5hFwB/SQmFUxYgFg4AAABQPjm0fT6kaiOyeC7DhDGdZB+tbx6vbh22dyStbRq2fzy6fzbQlkLLkUCwcyWSVg4AdkAFgU0SIxgQAAAASDo7qnVApGkjvoQ5yok1l1sXoF8ToF4TqGgbqmoZs3o0uXwy1p1JzpREqGkdmVwTAIdUF4NQEy8bChULBD4wKaFvPaJjHNmudvXKhaFnI3tEC3dCCYlREYZLCax4OtOWSNCUQcmNPK1tIKNiGQCGVBd3SBNlOQxvPhFeMg+hZyevcimvfkvUrHeldz+NXyqLWiGLWiGPWyCte0PtvoHuunTiqlu8fzKnZh0AdkYNdUYWb0IZjlghgE8eom00qnM0ilsukGM2kGY6l249nHNDqn9HsIZWzqV9zqR17MaO4q5jvH0wrW4mAFglBVglBzweClk7HH1YL5hxRYpfL4NaMHxVKoFWKJRoOpxyQZVoOOHAl+fLp5dmOL+QV9WmZsGDOJ5eGQAbCAMRCAQGBQQbEgdSNhZzUihsSR+bbDWfdkOacD2MYTSDViu5jF6yiWJ7TiluQRlpMQR+RhO4fDOlaCMAEQkEAwUFBwYFBQYFCwgELx0KRioNd08lflcudEsmjVsui1wycE0yLgoFThcCUCIJPBgGWiwEl1wauXkwAB0NAwsGAwYGBQgGBQIDBA0HA0ktDFYyD3dJHW1CHVYxFmApCXspAHAoA3gvBVkaAkgTAlMeA39JFqVrLVIIEk70jwPqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Château De Morey Chambre d'hôtes Gîtes location de salles détente lorraine Nancy Metz Spa Golf Piscine Mariage séminaire Séjour repos calme",
            "title": "Château De Morey Chambre d'hôtes Gîtes location de salles détente lorraine Nancy Metz Spa Golf Piscine Mariage séminaire Séjour repos calme",
            "src": "/static/70a3b19265fa86c48c8ce00f41e3a561/44385/LesChambresTourelle1.png",
            "srcSet": ["/static/70a3b19265fa86c48c8ce00f41e3a561/63868/LesChambresTourelle1.png 250w", "/static/70a3b19265fa86c48c8ce00f41e3a561/0b533/LesChambresTourelle1.png 500w", "/static/70a3b19265fa86c48c8ce00f41e3a561/44385/LesChambresTourelle1.png 527w"],
            "sizes": "(max-width: 527px) 100vw, 527px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambre d’hôtes, Gîtes et location de salles en lorraine située près de Nancy et Metz`}</strong></p>
    <h2><strong parentName="h2">{`AIRBNB`}</strong>{` entre `}<strong parentName="h2">{`Nancy`}</strong>{` et `}<strong parentName="h2">{`Metz`}</strong></h2>
    <p>{`Le charme de l’ancien, le confort de la modernité : réservez un séjour `}<strong parentName="p">{`Airbnb`}</strong>{` à Morey, à 15km de `}<a parentName="p" {...{
        "href": "http://www.nancy.fr"
      }}><strong parentName="a">{`Nancy`}</strong></a>{` et 30km de `}<a parentName="p" {...{
        "href": "http://metz.fr/"
      }}><strong parentName="a">{`Metz`}</strong></a>{`, dans un sublime château réhabilité.`}</p>
    <h2>{`Le Château de Morey, entre calme et convivialité`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b540804d0800c4fd224922eacc37773b/a2510/airbnb_nancy.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAXqEYhvGKZf/xAAcEAACAgIDAAAAAAAAAAAAAAABAgADERITISP/2gAIAQEAAQUCC9PrjkEpYipPRcT/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/Aax//8QAGRAAAwADAAAAAAAAAAAAAAAAAAEQETJB/9oACAEBAAY/AlNkZHyf/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFBcf/aAAgBAQABPyFlm3CAmyXAm2ezqhwuIEsPYbT/2gAMAwEAAgADAAAAEHDP/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QpX//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFB/9oACAECAQE/EJIWn//EABkQAQADAQEAAAAAAAAAAAAAAAEAETEhUf/aAAgBAQABPxBVUOhXEM0jugBvaVoY04XeJWCoqJbAeHkN6OyRN1mwBA5P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Airbnb Nancy",
            "title": "Airbnb Nancy",
            "src": "/static/b540804d0800c4fd224922eacc37773b/a2510/airbnb_nancy.jpg",
            "srcSet": ["/static/b540804d0800c4fd224922eacc37773b/0479a/airbnb_nancy.jpg 250w", "/static/b540804d0800c4fd224922eacc37773b/41099/airbnb_nancy.jpg 500w", "/static/b540804d0800c4fd224922eacc37773b/a2510/airbnb_nancy.jpg 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Airbnb Nancy`}</strong></p>
    <p>{`Nous mettons à votre disposition des `}<strong parentName="p">{`chambres d’hôtes`}</strong>{` `}<strong parentName="p">{`Airbnb`}</strong>{` toutes imprégnées de l’histoire et du `}<strong parentName="p">{`charme`}</strong>{` du château. La réhabilitation a permis d’allier `}<strong parentName="p">{`charme`}</strong>{` et confort, comme nulle part ailleurs. Passez quelques jours dans un château qui voit défiler les siècles…`}</p>
    <p>{`Vous pourrez, si le cœur vous en dit, faire la connaissance d’autres touristes, au détour du coin salon du château. La proximité de grandes villes vous permet de belles découvertes touristiques : la `}<a parentName="p" {...{
        "href": "http://www.nancy-tourisme.fr/decouvrir/histoire-et-patrimoine/unesco/place-stanislas/"
      }}>{`place Stanislas`}</a>{` de `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Nancy"
      }}><strong parentName="a">{`Nancy`}</strong></a>{`, la `}<a parentName="p" {...{
        "href": "http://www.cathedrale-metz.fr"
      }}>{`Cathédrale Saint-Etienne`}</a>{` de `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Metz"
      }}><strong parentName="a">{`Metz`}</strong></a>{`. Peu de locations `}<strong parentName="p">{`Airbnb`}</strong>{` vous permettent d’accéder à un tel patrimoine, depuis votre séjour au château jusqu’au visites des villes alentours.`}</p>
    <h2>{`Location `}<strong parentName="h2">{`Airbnb`}</strong>{` dans un cadre somptueux`}</h2>
    <p>{`Profitez d’une location `}<strong parentName="p">{`Airbnb`}</strong>{` près de `}<strong parentName="p">{`Nancy`}</strong>{` et `}<strong parentName="p">{`Metz`}</strong>{`, dans un cadre verdoyant.`}</p>
    <p>{`Depuis le château, admirez la Vallée de la Seille à perte de vue, profitez d’un séjour calme et reposant. Oubliez le stress de grandes villes comme `}<strong parentName="p">{`Nancy`}</strong>{` et `}<strong parentName="p">{`Metz`}</strong>{`, sans trop vous en éloigner.`}</p>
    <p>{`Une des plus belles destinations `}<strong parentName="p">{`Airbnb`}</strong>{` de `}<strong parentName="p">{`Lorraine`}</strong>{` !`}</p>
    <h2>{`Un concept tendance`}</h2>
    <p>{`Economique et convivial, le `}<strong parentName="p">{`B&B`}</strong>{` vous permet de trouver un hébergement ailleurs que dans un hôtel, dont les tarifs sont relativement élevés pour un hébergement plutôt classique et sommaire. Dans notre château, vous profitez d’une qualité d’hébergement bien supérieure à la plupart des hôtels, tout en bénéficiant d’un tarif raisonnable.`}</p>
    <p>{`Le concept est très en vogue, grâce à son côté à la fois pratique et économique. En choisissant notre établissement, vous aurez également le privilège d’admirer un décor inimitable de château du XVIèe siècle : unique pour une location `}<strong parentName="p">{`Airbnb`}</strong>{` !`}</p>
    <h2>{`Location `}<strong parentName="h2">{`Airbnb`}</strong>{` dans un cadre somptueux`}</h2>
    <p>{`Profitez d’une location `}<strong parentName="p">{`Airbnb`}</strong>{` près de `}<strong parentName="p">{`Nancy`}</strong>{` et `}<strong parentName="p">{`Metz`}</strong>{`, dans un cadre verdoyant.`}</p>
    <p>{`Depuis le château, admirez la Vallée de la Seille à perte de vue, profitez d’un séjour calme et reposant. Oubliez le stress de grandes villes comme `}<strong parentName="p">{`Nancy`}</strong>{` et `}<strong parentName="p">{`Metz`}</strong>{`, sans trop vous en éloigner.`}</p>
    <p>{`Une des plus belles destinations `}<strong parentName="p">{`Airbnb`}</strong>{` de `}<strong parentName="p">{`Lorraine`}</strong>{` !`}</p>
    <p>{`Nos pages Airbnb :`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.airbnb.co.uk/rooms/859712"
        }}>{`Airbnb UK`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.airbnb.fr/rooms/859712"
        }}>{`Airbnb France`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.airbnb.nl/rooms/859712"
        }}>{`Airbnb NL, Nancy, Frankrijk`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      